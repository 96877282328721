export const pages = [
  { index: 0, name: 'overview.md', route: '/overview', visible: true },
  { index: 1, name: 'experience.md', route: '/experience', visible: true },
  { index: 2, name: 'education.md', route: '/education', visible: true },
  { index: 3, name: 'project.md', route: '/project', visible: true },
  { index: 4, name: 'certificate.md', route: '/certificate', visible: true },
  { index: 5, name: 'volunteer.md', route: '/volunteer', visible: true },
  { index: 6, name: 'skill.md', route: '/skill', visible: true },
  { index: 7, name: 'language.md', route: '/language', visible: true },
  { index: 8, name: 'docs.md', route: '/docs', visible: false },  
];
